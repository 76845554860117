import { useState, useEffect } from "react";
import URLs from "constants/urls";
import { Business } from "@mui/icons-material";

const BASE_URL = URLs.baseUrl;

const useDashboardData = () => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [open, setOpen] = useState(false);
  const [loansChange, setLoansChange] = useState(0);
  const [insuranceChange, setInsuranceChange] = useState(0);
  const [mutualFundsChange, setMutualFundsChange] = useState(0);
  const [yearlyLoansChange, setYearlyLoansChange] = useState(0);
  const [yearlyInsuranceChange, setYearlyInsuranceChange] = useState(0);
  const [yearlyMutualFundsChange, setYearlyMutualFundsChange] = useState(0);
  const [monthlyOverViewData, setMonthlyOverviewData] = useState({
    IN: {
      businessType: "IN",
      totalApplications: 0,
      revenue: 0,
      business: 0,
      disbursedApplications: 0,
    },
    MF: {
      businessType: "MF",
      totalApplications: 0,
      revenue: 0,
      business: 0,
      disbursedApplications: 0,
    },
    BL: {
      businessType: "BL",
      totalApplications: 0,
      revenue: 0,
      business: 0,
      disbursedApplications: 0,
    },
  });
  const [activeEmployees, setActiveEmployees] = useState(0);
  const [barGraphMonthlyData, setBarGraphMonthlyData] = useState({
    labels: ["Loan", "Insurance", "Mutual Funds"],
    datasets: {
      label: "Business",
      data: [0, 0, 0],
    },
  });
  const [monthlyWiseEmployeeBusiness, setMonthlyWiseEmployeeBusiness] = useState({
    labels: ["0"],
    datasets: {
      label: "Business",
      data: [0],
    },
  });
  const [monthlyLineChartData, setMonthlyLineChartData] = useState({
    labels: [1],
    datasets: [
      {
        label: "Business from Loans (₹)",
        data: [0],
        tension: 0,
        pointRadius: 5,
        pointBorderColor: "transparent",
        pointBackgroundColor: "rgba(255, 255, 255, .8)",
        borderColor: "rgba(255, 255, 255, .8)",
        borderWidth: 4,
        backgroundColor: "transparent",
        fill: true,
        maxBarThickness: 6,
      },
      {
        label: "Business from Insurance (₹)",
        data: [0],
        tension: 0,
        pointRadius: 5,
        pointBorderColor: "transparent",
        pointBackgroundColor: "#FFC107",
        borderColor: "#FFC107",
        borderWidth: 4,
        backgroundColor: "transparent",
        fill: true,
        maxBarThickness: 6,
      },
      {
        label: "Business from Mutual Funds (₹)",
        data: [0],
        pointRadius: 5,
        pointBorderColor: "transparent",
        pointBackgroundColor: "#FF5722",
        borderColor: "#FF5722",
        borderWidth: 4,
        backgroundColor: "transparent",
        fill: true,
        maxBarThickness: 6,
      },
    ],
  });
  const [yearlyLineChartData, setYearlyLineChartData] = useState({
    labels: ["Jan"],
    datasets: [
      {
        label: "Business from Loans (₹)",
        data: [0],
        tension: 0,
        pointRadius: 5,
        pointBorderColor: "transparent",
        pointBackgroundColor: "rgba(255, 255, 255, .8)",
        borderColor: "rgba(255, 255, 255, .8)",
        borderWidth: 4,
        backgroundColor: "transparent",
        fill: true,
        maxBarThickness: 6,
      },
      {
        label: "Business from Insurance (₹)",
        data: [0],
        tension: 0,
        pointRadius: 5,
        pointBorderColor: "transparent",
        pointBackgroundColor: "#FFC107",
        borderColor: "#FFC107",
        borderWidth: 4,
        backgroundColor: "transparent",
        fill: true,
        maxBarThickness: 6,
      },
      {
        label: "Business from Mutual Funds (₹)",
        data: [0],
        pointRadius: 5,
        pointBorderColor: "transparent",
        pointBackgroundColor: "#FF5722",
        borderColor: "#FF5722",
        borderWidth: 4,
        backgroundColor: "transparent",
        fill: true,
        maxBarThickness: 6,
      },
    ],
  });
  const [monthlyDsaBusinessData, setMonthlyDsaBusinessData] = useState({
    labels: ["0"],
    datasets: {
      label: "Monthly DSA Business",
      data: [0],
    },
  });
  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const extractDays = (labels) => {
    if (!labels || !Array.isArray(labels)) {
      console.error("Labels is undefined or not an array:", labels);
      return [];
    }
    return labels.map((dateString) => new Date(dateString).getDate());
  };
  const calculatePercentageChange = (previousValue, currentValue) => {
    if (previousValue === 0) {
      return currentValue === 0 ? 0 : 100; // 100% increase if previous is 0 and current is greater than 0
    }
    return ((currentValue - previousValue) / previousValue) * 100;
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const currentDate = new Date().toISOString().split("T")[0]; // Define currentDate here
      // const currentDate = "2024-10-29";
      const dataResponse = await fetch(`${BASE_URL}/api/dashboard?date=${currentDate}`);
      const dataResult = await dataResponse.json();
      const monthlyLineGraphData = {
        labels: dataResult.monthToDateDetailGraph.dates
          ? extractDays(dataResult.monthToDateDetailGraph.dates)
          : [1],
        datasets: [
          {
            label: "Business from Loans (₹)",
            data: dataResult.monthToDateDetailGraph.loan || [0],
            tension: 0,
            pointRadius: 5,
            pointBorderColor: "transparent",
            pointBackgroundColor: "rgba(255, 255, 255, .8)",
            borderColor: "rgba(255, 255, 255, .8)",
            borderWidth: 4,
            backgroundColor: "transparent",
            fill: true,
            maxBarThickness: 6,
          },
          {
            label: "Business from Insurance (₹)",
            data: dataResult.monthToDateDetailGraph.insurance || [0],
            tension: 0,
            pointRadius: 5,
            pointBorderColor: "transparent",
            pointBackgroundColor: "#FFC107",
            borderColor: "#FFC107",
            borderWidth: 4,
            backgroundColor: "transparent",
            fill: true,
            maxBarThickness: 6,
          },
          {
            label: "Business from Mutual Funds (₹)",
            data: dataResult.monthToDateDetailGraph.mutualfund || [0],
            pointRadius: 5,
            pointBorderColor: "transparent",
            pointBackgroundColor: "#FF5722",
            borderColor: "#FF5722",
            borderWidth: 4,
            backgroundColor: "transparent",
            fill: true,
            maxBarThickness: 6,
          },
        ],
      };
      const yearLineChartData = {
        labels: dataResult.yearToDateDetailGraph.month,
        datasets: [
          {
            label: "Business from Loans (₹)",
            data: dataResult.yearToDateDetailGraph.loan || [0],
            tension: 0,
            pointRadius: 5,
            pointBorderColor: "transparent",
            pointBackgroundColor: "rgba(255, 255, 255, .8)",
            borderColor: "rgba(255, 255, 255, .8)",
            borderWidth: 4,
            backgroundColor: "transparent",
            fill: true,
            maxBarThickness: 6,
          },
          {
            label: "Business from Insurance (₹)",
            data: dataResult.yearToDateDetailGraph.insurance || [0],
            tension: 0,
            pointRadius: 5,
            pointBorderColor: "transparent",
            pointBackgroundColor: "#FFC107",
            borderColor: "#FFC107",
            borderWidth: 4,
            backgroundColor: "transparent",
            fill: true,
            maxBarThickness: 6,
          },
          {
            label: "Business from Mutual Funds (₹)",
            data: dataResult.yearToDateDetailGraph.mutualfund || [0],
            pointRadius: 5,
            pointBorderColor: "transparent",
            pointBackgroundColor: "#FF5722",
            borderColor: "#FF5722",
            borderWidth: 4,
            backgroundColor: "transparent",
            fill: true,
            maxBarThickness: 6,
          },
        ],
      };
      setYearlyLineChartData(yearLineChartData);
      setMonthlyLineChartData(monthlyLineGraphData);
      setBarGraphMonthlyData({
        labels: ["Loan", "Insurance", "Mutual Funds"],
        datasets: {
          label: "Business",
          data: [
            dataResult.monthToDateAbstractGraph.loan || 0,
            dataResult.monthToDateAbstractGraph.insurance || 0,
            dataResult.monthToDateAbstractGraph.mutualfund || 0,
          ],
        },
      });
      setMonthlyDsaBusinessData({
        labels: dataResult.monthToDateDSAAbstractlGraph.dsacode,
        datasets: {
          label: "Monthly DSA Business",
          data: dataResult.monthToDateDSAAbstractlGraph.business,
        },
      });
      setMonthlyWiseEmployeeBusiness({
        labels: dataResult.monthToDateEmployeeAbstractlGraph.salesManager,
        datasets: {
          label: "Business",
          data: dataResult.monthToDateEmployeeAbstractlGraph.business,
        },
      });
      const businessTypes = ["IN", "MF", "BL"];

      const monthlyOverviewData = businessTypes.reduce((acc, type) => {
        const businessData = dataResult.monthlyOverview.find((item) => item.businessType === type);

        acc[type] = {
          businessType: type,
          totalApplications: businessData ? businessData.totalApplications : 0,
          revenue: businessData ? businessData.revenue : 0,
          business: businessData ? businessData.business : 0,
          disbursedApplications: businessData ? businessData.disbursedApplications : 0,
        };

        return acc;
      }, {});
      setMonthlyOverviewData(monthlyOverviewData);
      setActiveEmployees(dataResult.monthToDateActiveEmployees.activeEmployees);
    } catch (error) {
      setAlert(error.message || "An error occurred", "error");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData once on mounth
  }, []);

  return {
    monthlyLineChartData,
    barGraphMonthlyData,
    yearlyLineChartData,
    loansChange,
    insuranceChange,
    mutualFundsChange,
    yearlyLoansChange,
    yearlyInsuranceChange,
    yearlyMutualFundsChange,
    monthlyDsaBusinessData,
    monthlyWiseEmployeeBusiness,
    monthlyOverViewData,
    activeEmployees,
    loading,
    setAlert,
    alertType,
    message,
    handleClose,
  };
};

export default useDashboardData;
