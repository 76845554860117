import { useState, useEffect, useCallback } from "react";
import URLs from "constants/urls";

const useBusinessEntryApiCall = (typeCode, resetForm) => {
  const [editStatus, setEditStatus] = useState(false);
  const [businessMasterData, setBusinessMasterData] = useState([]);
  const [providerData, setProviderData] = useState([]);
  const [finalFinanceData, setFinalFinanceData] = useState([]);
  const [dsaData, setDsaData] = useState([]);
  const [entryData, setEntryData] = useState([]);
  const [subDsaData, setSubDsaData] = useState([]);
  const [managersData, setManagersData] = useState([]);
  const [dsaManagersData, setDsaManagersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [open, setOpen] = useState(false);

  const setAlert = (msg, type) => {
    setMessage(msg);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fetchFinanceDetails = async (selectedLoanType) => {
    if (!selectedLoanType) return;
    const url = `${URLs.entriesUrlById}/${selectedLoanType}`;
    setLoading(true);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch finance details");
      }
      const data = await response.json();
      setFinalFinanceData(data);
    } catch (error) {
      setAlert("" + error.message, "error");
    }
    setLoading(false);
  };
  const fetchFilteredFinanceDetails = async (bType, bStatus) => {
    const url = `${URLs.baseUrl}/filter?businessStatus=${bStatus}&businessType=${bType}`;
    setLoading(true);
    const options = {
      method: "GET",
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      setEntryData(data);
    } catch (error) {
      setAlert(`Data not fetched: ${error.message}`, error);
    }
    setLoading(false);
  };
  // Memoize the fetch function to prevent re-creation on each render
  const fetchEntriesFeildsData = useCallback(async () => {
    setLoading(true);
    try {
      // Step 1: Fetch Role Details
      const roleDetailsUrl = `${URLs.roleGetUrl}`;
      const roleResponse = await fetch(roleDetailsUrl);

      if (!roleResponse.ok) {
        throw new Error("Failed to fetch role details");
      }

      const roleData = await roleResponse.json();

      // Extract Role IDs for Sales Manager and Branch Manager
      const dsaMngrRole = roleData.find((item) =>
        item.roleName.toLowerCase().includes("sales manager")
      );
      const subDsaMngrRole = dsaMngrRole;
      if (!dsaMngrRole || !subDsaMngrRole) {
        throw new Error("Role data not found");
      }
      // Step 2: Fetch Employees by Role ID
      const employeeDetailsUrl = `${URLs.employeeDetailsByRoleIdUrl}/${dsaMngrRole.roleId}`;
      const subDsaEmployeeDetailsUrl = `${URLs.employeeDetailsByRoleIdUrl}/${subDsaMngrRole.roleId}`;

      const [employeeResponse, subDsaEmployeeResponse] = await Promise.all([
        fetch(employeeDetailsUrl),
        fetch(subDsaEmployeeDetailsUrl),
      ]);

      if (!employeeResponse.ok || !subDsaEmployeeResponse.ok) {
        throw new Error("Failed to fetch employee data");
      }

      const employeeData = await employeeResponse.json();
      const subDsaEmployeeData = await subDsaEmployeeResponse.json();

      // Step 3: Fetch Other Data in Parallel
      const urls = {
        businessMasterUrl: `${URLs.financeBusinessGetUrl}/${typeCode}`,
        providerUrl: `${URLs.finanaceGetUrlByTypeCodeUrl}/${typeCode}`,
        dsaDetailsUrl: `${URLs.dsaMasterGetUrl}`,
        businessEntriesUrl: `${URLs.businessEntriesUrl}/${typeCode}`,
      };

      const [businessMasterResponse, providerResponse, dsaResponse, entriesResponse] =
        await Promise.all(Object.values(urls).map((url) => fetch(url)));

      if (
        !businessMasterResponse.ok ||
        !providerResponse.ok ||
        !dsaResponse.ok ||
        !entriesResponse.ok
      ) {
        throw new Error("Failed to fetch some data");
      }
      const [businessMasterData, providerData, dsaData, businessEntriesData] = await Promise.all([
        businessMasterResponse.json(),
        providerResponse.json(),
        dsaResponse.json(),
        entriesResponse.json(),
      ]);
      // Filter and process data
      const filteredDsaMngr = employeeData.filter(
        (item) => item.role.roleId === dsaMngrRole.roleId && item.companyId == 1
      );
      const filteredSubDsaMngr = subDsaEmployeeData.filter(
        (item) => item.role.roleId === subDsaMngrRole.roleId && item.companyId != 1
      );
      // const filteredSubDsaMngr = filteredDsaMngr;
      const filteredSubDsaData = dsaData.data.filter((item) => item.dsaStatus == "O");

      // Set data to states
      setBusinessMasterData(businessMasterData);
      setProviderData(providerData);
      setDsaData(dsaData.data);
      setSubDsaData(filteredSubDsaData);
      setManagersData(filteredDsaMngr);
      setDsaManagersData(filteredSubDsaMngr);
      setEntryData(businessEntriesData);
    } catch (error) {
      console.error("Error while fetching data:", error);
      setAlert("Failed to fetch data: " + error.message, "error");
    }
    setLoading(false);
  }, [typeCode]); // Only recreate the function when typeCode changes
  // Create Loan Entry

  const fetchBusinessEntriesApiCall = useCallback(async () => {
    const url = `${URLs.baseUrl}/businessentries/${typeCode}`;
    setLoading(true);
    const options = {
      method: "GET",
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      setEntryData(data);
    } catch (error) {
      setAlert(`Data not fetched: ${error.message}`, error);
    }
    setLoading(false);
  }, [typeCode]);

  const postEntry = useCallback(
    async (data) => {
      setLoading(true);
      const url = `${URLs.baseUrl}/businessEntries`;
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });

        const responseData = await response.json(); // Parse the response JSON

        if (response.ok) {
          setAlert(responseData.message || "Posted Successfully", "success"); // Use the message from the server
          fetchBusinessEntriesApiCall();
          if (resetForm) resetForm();
        } else {
          // If the response is not OK, show the server's error message
          const errorMessage = responseData.message || "Network response was not ok";
          throw new Error(errorMessage);
        }
      } catch (error) {
        setAlert("Failed to Post: " + error.message, "error");
      }
      setLoading(false);
    },
    [fetchBusinessEntriesApiCall, resetForm]
  );
  // Update Loan Entry
  const putEntry = useCallback(
    async (entryId, data) => {
      setLoading(true);
      const url = `${URLs.baseUrl}/businessEntries/${entryId}`;
      try {
        const response = await fetch(url, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          setAlert("Updated Successfully", "success"); // Successful update alert
          fetchBusinessEntriesApiCall();
          if (resetForm) resetForm();
        } else {
          // Parse the error message from the JSON response
          const errorData = await response.json();
          const errorMessage = errorData.message || "Failed to Update"; // Use the server error message
          setAlert(errorMessage, "error"); // Show the error message in an alert
        }
      } catch (error) {
        setAlert("Failed to Update: " + error.message, "error"); // Handle network or other errors
      }
      setLoading(false);
    },
    [fetchBusinessEntriesApiCall, resetForm]
  );
  // Delete Loan Entry
  const deleteEntry = useCallback(
    async (entryId) => {
      setLoading(true);
      const url = `${URLs.baseUrl}/businessEntries/${entryId}`;
      try {
        const response = await fetch(url, {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
        });

        if (response.ok) {
          setAlert("Deleted Successfully", "success");
          fetchBusinessEntriesApiCall();
          if (resetForm) resetForm();
        } else {
          // If the response is not ok, parse the response body to get the error message
          const errorData = await response.json();
          setAlert(errorData.message || "Failed to Delete", "error");
        }
      } catch (error) {
        setAlert("Failed to Delete: " + error.message, "error");
      }
      setLoading(false);
    },
    [fetchBusinessEntriesApiCall]
  );

  useEffect(() => {
    if (typeCode) {
      fetchEntriesFeildsData(); // Call once when the component mounts or typeCode changes
    }
  }, [fetchEntriesFeildsData]);
  return {
    businessMasterData,
    providerData,
    dsaData,
    entryData,
    subDsaData,
    managersData,
    dsaManagersData,
    message,
    alertType,
    open,
    editStatus,
    finalFinanceData,
    setLoading,
    loading,
    setAlert,
    handleClose,
    setEditStatus,
    setOpen,
    postEntry,
    putEntry,
    deleteEntry,
    fetchFinanceDetails,
    fetchBusinessEntriesApiCall,
    fetchFilteredFinanceDetails,
    handleClose,
  };
};

export default useBusinessEntryApiCall;
